<template>
  <section class="page-container">
    <div
      class="header-title-back flex"
      @click="() => $router.back()"
    >
      <i class="el-icon-arrow-left"></i>
      <span>
        Back
      </span>
    </div>

    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <el-row
        :gutter="40"
        justify="space-around">

        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="17"
          :xl="17"
          class="page-content-and-up"
        >
          <h2 class="title-page">
            {{isEdit?'Edit Policy':'Cancel Policy'}}
          </h2>
          <cancel-form
            ref="cancellation"
            :form="formData"
            :show-premium="isEdit"
            :refund.sync="refundTotal"
            @getFormData="getSubFormData"
          >
            <template slot="form">
              <v-title title="Policy Summary"></v-title>
              <moto-info
                v-if="productId==10"
                :formData="formData"></moto-info>
              <constractors-info
                v-if="productId==11"
                :formData="formData"></constractors-info>
              <travel-info
                v-if="productId==12"
                :formData="formData"></travel-info>
              <home-info
                v-if="productId==13"
                :formData="formData"></home-info>
              <employees-info
                v-if="productId==14"
                :formData="formData"></employees-info>
              <business-info
                v-if="productId==15"
                :formData="formData"></business-info>
              <domestic-info
                v-if="productId==16"
                :formData="formData"></domestic-info>
              <construction-info
                v-if="productId==17"
                :formData="formData"></construction-info>
              <other-info
                v-if="productId==18"
                :formData="formData"></other-info>
            </template>
          </cancel-form>

        </el-col>
        <el-col
          class="padding-none-sm-and-down  page-content-auto"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="5"
          :xl="5">
          <div class="page-content-fixed">
            <cancel-dialog
              :isSave="is_save"
              :form="formData"
              :isNext="!hasPayment"
              :refund="refundTotal"
              @handlerConfirm="handlerConfirm"
              @handlerNext="hanlderNext"></cancel-dialog>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import motoInfo from '@/views/quotation/motor/motoInfo'
import constractorsInfo from '@/views/quotation/contractors/constractorsInfo'
import travelInfo from '@/views/quotation/travel/travelInfo'
import homeInfo from '@/views/quotation/home/homeInfo'
import employeesInfo from '@/views/quotation/employees/employeesInfo'
import businessInfo from '@/views/quotation/business/businessInfo'
import domesticInfo from '@/views/quotation/domestic/domesticInfo'
import constructionInfo from '@/views/quotation/construction/constructionInfo'
import otherInfo from '@/views/quotation/other/otherInfo'
import cancelForm from '@/views/components/motor/cancelForm'
import cancelDialog from '@/views/components/dialog/cancelDialog'
import {cancelQuotationMotor,cancelQuotationConstrator,cancelQuotationTravel,cancelQuotationHome,cancelQuotationEmployee,cancelQuotationBusiness,cancelQuotationDomestic,cancelQuotationContruction,cancelQuotationOther} from '@/api/cancellation'
import MixinRules from '@/mixins/rules'
import MixinOptions from '@/mixins/options'
import { mapState, mapActions } from 'vuex'
import {setOrderConfirm} from '@api/order'
export default {
  name: 'endorsementQuotation',
  components: {
    motoInfo,
    constractorsInfo,
    travelInfo,
    homeInfo,
    employeesInfo,
    businessInfo,
    domesticInfo,
    constructionInfo,
    otherInfo,
    cancelForm,
    cancelDialog
  },
  mixins: [MixinRules,MixinOptions],
  data() {
    return {
      subForm: {
        cancellation:false
      },
      formData: {
        draft:false,
      },
      refundTotal:0,
    }
  },
  computed: {
    ...mapState('quotation', ['quoteInformationForm']),
    isEdit(){
      let {order_no,isDraft} = this.$route.query||{}
      if(isDraft=='true')
        return true
      if(this.quoteInformationForm)
      {
        let {is_edit=false} = this.quoteInformationForm
        return is_edit?true:false
      }return  false
    },
    hasPayment(){
      let {payment} = this.quoteInformationForm||{}
      return !!payment
    },
    is_save(){
      if(this.quoteInformationForm)
      {
        let {is_save=false} = this.quoteInformationForm
        if(this.isEdit){
          return this.hasPayment
        }
        return is_save
      }return  false
    },
    productId(){
      let { product_id} = this.quoteInformationForm||{}
      return product_id
    }
  },
  created() {
    if(this.quoteInformationForm){
      this.formData = Object.assign(this.formData,this.quoteInformationForm)
    }
  },
  methods: {
    ...mapActions('quotation', ['setQuoteInformationForm']),
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    handlerBack() {
      this.$router.back()
    },
    submitData(data){
      this.formData = Object.assign(this.formData, data)
      this.network().endorsementQuotationMotor()
    },
    handlerValidate() {
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      switch (this.productId){
        case 10:
          this.network().cancelQuotationMotor()
          break
        case 11:
          this.network().cancelQuotationConstrator()
          break
        case 12:
          this.network().cancelQuotationTravel()
          break
        case 13:
          this.network().cancelQuotationHome()
          break
        case 14:
          this.network().cancelQuotationEmployee()
          break
        case 15:
          this.network().cancelQuotationBusiness()
          break
        case 16:
          this.network().cancelQuotationDomestic()
          break
        case 17:
          this.network().cancelQuotationContruction()
          break
        case 18:
          this.network().cancelQuotationOther()
          break
      }

    },
    hanlderNext() {
      this.handlerValidate()
      // let {order_no} = this.quoteInformationForm
      // this.is_save?
      //   this.$router.back():this.$router.push({
      //     name: 'info',
      //     query:{
      //       order_no,
      //       isCancel:true
      //     }
      //   })
    },
    handlerConfirm(){
      this.handlerValidate()
    },
    async handleLink(order_no){
      if(this.isEdit&&!this.hasPayment){
        await this.network().setOrderConfirm()
      }
      this.$router.push({
        path:'/quotation/premium/info',
        query:{
          order_no
        }
      })
    },
    network() {
      return {
        setOrderConfirm:async ()=>{
          let order_no = this.$route.query.order_no
          let {policy_id,policy_no,covernote_no,policy_document_id,covernote_document_id} = this.quoteInformationForm
          let params = {order_no:order_no,policy_no,covernote_no,covernote_document_id,policy_document_id}
          let { data } = await setOrderConfirm(params)
          this.$router.push({
            path:'/quotation/premium/info',
            query:{
              order_no
            }
          })
        },
        cancelQuotationMotor: async () => {
          // let {order_no} = this.quoteInformationForm
          let {is_edit} = this.quoteInformationForm
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await cancelQuotationMotor(params)
          let {order_no}  =data
          this.handleLink(order_no)
        },
        cancelQuotationConstrator: async () => {
          // let {order_no} = this.quoteInformationForm
          let {is_edit} = this.quoteInformationForm
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await cancelQuotationConstrator(params)
          let {order_no}  =data
          this.handleLink(order_no)
        },
        cancelQuotationTravel: async () => {
          // let {order_no} = this.quoteInformationForm
          let {is_edit} = this.quoteInformationForm
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await cancelQuotationTravel(params)
          let {order_no}  =data
          this.handleLink(order_no)
        },
        cancelQuotationHome: async () => {
          // let {order_no} = this.quoteInformationForm
          let {is_edit} = this.quoteInformationForm
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await cancelQuotationHome(params)
          let {order_no}  =data
          this.handleLink(order_no)
        },
        cancelQuotationEmployee: async () => {
          // let {order_no} = this.quoteInformationForm
          let {is_edit} = this.quoteInformationForm
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await cancelQuotationEmployee(params)
          let {order_no}  =data
          this.handleLink(order_no)
        },
        cancelQuotationBusiness: async () => {
          // let {order_no} = this.quoteInformationForm
          let {is_edit} = this.quoteInformationForm
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await cancelQuotationBusiness(params)
          let {order_no}  =data
          this.handleLink(order_no)
        },
        cancelQuotationDomestic: async () => {
          // let {order_no} = this.quoteInformationForm
          let {is_edit} = this.quoteInformationForm
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await cancelQuotationDomestic(params)
          let {order_no}  =data
          this.handleLink(order_no)
        },
        cancelQuotationContruction: async () => {
          // let {order_no} = this.quoteInformationForm
          let {is_edit} = this.quoteInformationForm
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await cancelQuotationContruction(params)
          let {order_no}  =data
          this.handleLink(order_no)
        },
        cancelQuotationOther: async () => {
          // let {order_no} = this.quoteInformationForm
          let {is_edit} = this.quoteInformationForm
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await cancelQuotationOther(params)
          let {order_no}  =data
          this.handleLink(order_no)
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-container {
  position: relative;
  //padding: 15px;
  .header-title {
    color: #000000;
    font-weight: 700;
    width: 100%;


    span {
      font-size: 16px;
    }

    &.flex {
      padding: 20px 0;
      align-items: center;

      i {
        margin-right: 10px;
      }
    }
  }

  .main-container {
    .eff-box {
      width: calc(50% - 10px);
    }

    .document-wrapper {
      .flex {
        .form-item {
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .drivers-file-container {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        .card {
          position: relative;
          margin-bottom: 15px;
          width: calc(50% - 10px);

          &:nth-child(2n-1) {
            margin-right: 10px;
          }

          &:nth-child(2n) {
            margin-left: 10px;
          }

          .mask {
            z-index: 2;
            position: absolute;
            top: 40px;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }
  }

  .el-col {
    padding: 0;
  }
}
</style>
